.icon_btn {
    width: 26.1vw;
    height: 5.6vw;
    border-radius: 3vw;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 6px 6px 25px #3F51B5;
}
.icon_btn:hover{
    opacity: 0.9;
    cursor: pointer;
}
.icon_btn:active{
    opacity: 0.8;
}
.icon_img {
    width: 2.5vw;
    margin-right: 17px;
}
.icon_lab {
    font-family: SemiBold;
    color: white;
    font-size: 1.19vw;
    margin: 0;
}

.back_btn {
    background-color: rgb(33, 48, 184);
    height: 5.6vw;
    border-radius: 3vw;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 6px 6px 25px #3F51B5;
    width: 10vw;
}
.back_btn:hover{
    opacity: 0.9;
    cursor: pointer;
}
.back_btn:active{
    opacity: 0.8;
}