@media (max-width: 1000px) {
  .top-alert-long {
    display: none
  }
  .top-alert-short {
    display: block
  }
}

@media (min-width: 1000px) {
  .top-alert-long {
    display: block
  }
  .top-alert-short {
    display: none
  }
}