 
@media (prefers-color-scheme: dark) {
  /* .modal-content { background: #303030 !important; color: #fff !important; } */

}

@media (prefers-color-scheme: light) {
  /* .modal-content { background: #fafafa  !important;; color: rgba(0, 0, 0, 0.87)  !important;; } */
}
.modal-body p {
    word-wrap: break-word;
}
#anydesk_modal {
    width: 350px
}
.info_div {
    display: flex;
    flex-direction: row;
}
.p_value {
    color: blue
}
.p_name {
    margin-right: 1rem;
}
#location_video_modal {
    max-width: 100vh;
}
.modal_title{
    font-family: Poppins;
}
#emoji_modal{
    max-width: 800px;
}

#emoji_modal .modal-content .modal-body {
    justify-content: space-around;
    display: flex;
}

/* Number Pad  */
.numpad-row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.big-key {
  font-size: 25px !important;
  font-weight: 600 !important;
  width: 4em !important;
  height: 4em !important;
}

.key {
  padding: 1em;
  margin: 2px;
  background-color: #efeff5;
  outline: 0px;
  border: 0px;
  border-radius: 10em;
  width: 4em;
  height: 4em;
  font-weight:bolder;
  /* transition: .5s;
  animation-duration: 1s;
  animation-fill-mode: both; */
}
.key::after { 
  border: 0px;
}
.key::-moz-focus-outer { 
  border: 0;
}
.key:hover {
  background-color: #00000022;
}

.big-action-key {
  font-size: 20px !important;
}

.key-action {
  padding: 1em;
  background-color: transparent;
  outline: 0px;
  border: 0px;
  border-radius: 10em;
  width: 5em;
  height: 5em;
}

.key-action:hover {
  background-color: #00000022;
}

.input-numpad  {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border: 0px;
  text-align: center;
  font-size: larger;
  font-weight: bold;
}

.input-numpad-big {
  font-size: 25px !important;
  font-weight: 600 !important;
}

img.administrator-icon {
  width: 15px;
  position: absolute;
  top: 10px;
  left: 14px;
}

.gallery-header {
  display: flex;
  justify-content: space-between;
  line-height: 1;
}

.gallery-item {
  width: 23.9%;
  height: 200px;
  margin: 0.5%;
  background-position: center;
  border-radius: 5px;
  display: inline-grid;
  background-size: cover;
}
.gallery-item:hover {
  cursor: pointer;
  box-shadow: inset 0px 0px 0px 2px #1C1D1E;
}
.gallery-item:hover  .gallery-remove-icon{
  display: inline-flex !important;
}
.gallery-item:hover  .gallery-edit-icon{
    display: inline-flex !important;
}

.gallery-item:hover .gallery-download-icon {
display: inline-flex !important;
}

.gallery-remove-icon{
  font-size: 27px !important;
  padding: 7px 8px 7px 8px !important;
  display:none !important;
  color:red !important;
}
.gallery-edit-icon{
  font-size: 27px !important;
  padding: 7px 8px 7px 8px !important;
  display:none !important;
  color:blue !important;
}
.gallery-download-icon{
  font-size: 27px !important;
  padding: 7px 8px 7px 8px !important;
  display:none !important;
  color:blue !important;
  font-weight:bolder !important;
}
.gallery-actions {
  display:flex;
  justify-content:space-between;
  align-items:end; 
}
.gallery-number{
  color:white;
  border-radius:5px;
  background-color:#4C4D4D;
  padding:0px 5px;
  padding-top: 1px;
  margin: 7px;
}

/* Color buttons */
.button-yellow {
  color: #757575 !important;
  background-color: #ffeb3b !important;
}
.button-yellow:hover{
    background-color: #ffff00 !important;
}
.button-red {
  color: #fff !important;
  background-color: #f44336 !important;
}
.button-red:hover {
    background-color: #ff0000 !important;
}
.button-green {
  color: #fff !important;
  background-color: #4caf50 !important;
}
.button-green:hover{
  background-color: #008800 !important;
}

.iframe {
  height: 250px;
  width: 100%;
  border-radius:5px;
  margin-top:5px;
}