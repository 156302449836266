.location_stage_area {
    display: flex;
    width: 100%;
    height: 62.5%;
    padding: 2.9vw 2.9vw 0 2.9vw;
}

.location_feature_area {
    display: flex;
    width: 23.5%;
    flex-direction: column;
    padding-left: 2.4vw;
    gap: 10px;
}

.location_video_area {
    width: 100%;
    display: flex;
    position: relative;
    background: black;
    align-items: center;
    justify-content: center;
    border: 1px solid;
}

.location_video {
    display: flex;
    width: 100%;
    height: 100%;
}

.location_name_area {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.location_img_div {
    display: flex;
    width: 3.3vh;
    height: 4.3vh;
    justify-content: end;
    align-items: center;
}

.location_img {
    height: max(1.56vw, 2.7vh);
}

.location_name_lab {
    font-size: max(1.35vw, 18px);
    font-family: Poppins;
    margin: 0px;
    margin-left: 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-transform: capitalize;
}

#remote_lab {
    color: #009cff;
    text-decoration: underline;
    cursor: pointer;
}

.call_button_area {
    width: 100%;
    height: 100%;
    display: flex;
}

.call_icon_div {
    width: min(17vh, 9.68vw);
    height: min(17vh, 9.68vw);
    display: flex;
    margin-top: auto;
}

#call_bottom_div {
    margin-bottom: auto;
}

.call_icon_img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.call_icon_img:hover {
    cursor: pointer;
    opacity: 0.87;
}

#hold_img {
    box-shadow: 0px 8px 25px #fe00068c;
    background: red;
}

#call_img {
    box-shadow: 0px 8px 25px #21b830;
    background: #21b830;
}

.location_name_on_video {
  margin-top: 5px;
  margin-left: 5px;
  position: absolute !important;
  top: 0;
  left: 0;
  color: #dddddd
}
.full_screen_btn {
    min-width: auto !important;
    outline: 0px !important;
    padding: 0px !important;
    position: absolute !important;
    bottom: 0;
    right: 0;
}

.full_screen_btn .MuiButton-label {
    background: #ffffff26;
    border-radius: inherit;
}

.control_btn {
    width: 100% !important;
    padding: 10px 19px !important;
    border-radius: 40px !important;
    outline: 0px !important;
}

.control_btn .MuiButton-label {
    justify-content: space-between;
}

.dialog_btn {
    padding: 10px 19px !important;
    border-radius: 40px !important;
    outline: 0px !important;
    font-weight: bolder !important;
}

.dialog_btn .MuiButton-label {
    justify-content: space-between;
    align-items: unset;
}

.logo {
    width: 65%;
}

.call_connnect_status {
    position: absolute;
    left: 0px;
    top: 0px;
    color: white;
    padding: 1px 5px;
    font-family: Poppins;
    background: #262626;
    text-transform: uppercase;
    font-weight: bold;
    border-radius: 5px;
}

.glow {
    display: flex;
    margin-top: auto;
    align-items: center;
    border-radius: 50%;
    margin-bottom: auto;
    justify-content: center;
}

@keyframes glowing {
    0% {
        background-color: #21b830;
        box-shadow: 0 0 5px #21b830;
    }

    50% {
        background-color: #21b830;
        box-shadow: 0 0 20px #21b830;
    }

    100% {
        background-color: #21b830;
        box-shadow: 0 0 5px #21b830;
    }
}

.glow {
    animation: glowing 1300ms infinite;
}

.location_list_area {
    display: flex;
    width: 100%;
    height: 37.5%;
    padding: 0 2.9vw;
    overflow: auto;
}

.slider_area {
    width: 94%;
    height: 100%;
}

.slider_area .slick-list {
    height: 100%;
}

.slider_area .slick-list .slick-track {
    height: 100%;
}

.slider_area .slick-list .slick-track .slick-slide>div {
    height: 50%;
    display: flex;
    align-items: center;
}

.slider_area .slick-list .slick-track .slick-slide>div:nth-child(1) {
    padding-top: 2%;
}

.slider_area .slick-list .slick-track .slick-slide>div:nth-child(2) {
    padding-bottom: 2%;
}

.button {
    position: relative;
    display: inline-block;
    cursor: pointer;
    transition: all 0.4s ease-in;
}

.button:before {
    content: '';
    background-color: rgb(250, 0, 0);
    border-radius: 50%;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-transform: scale(0.001, 0.001);
    transform: scale(0.001, 0.001);
}

.button:focus {
    outline: 0;
    color: rgb(255, 0, 0);
}

.button:focus:before {
    -webkit-animation: Click_Effect 0.8s ease-out;
    animation: Click_Effect 0.8s ease-out;
}

@-webkit-keyframes Click_Effect {
    50% {
        -webkit-transform: scale(1.5, 1.5);
        transform: scale(1.5, 1.5);
        opacity: 0;
    }

    99% {
        -webkit-transform: scale(0.001, 0.001);
        transform: scale(0.001, 0.001);
        opacity: 0;
    }

    100% {
        -webkit-transform: scale(0.001, 0.001);
        transform: scale(0.001, 0.001);
        opacity: 1;
    }
}

@keyframes Click_Effect {
    50% {
        -webkit-transform: scale(1.5, 1.5);
        transform: scale(1.5, 1.5);
        opacity: 0;
    }

    99% {
        -webkit-transform: scale(0.001, 0.001);
        transform: scale(0.001, 0.001);
        opacity: 0;
    }

    100% {
        -webkit-transform: scale(0.001, 0.001);
        transform: scale(0.001, 0.001);
        opacity: 1;
    }
}

/* Hide Menu component */
.lucas_logo {
    position: absolute;
    left: 4.6vw;
    top: 5.5vh;
    width: 10%;
    z-index: 1;
}

/* Location call tag */

.calltag_div_doctor {
    position:absolute;
    bottom:0;
    border-radius:5px;
    padding:5px 10px 5px 10px;
    margin-bottom:9rem;
    color:white;
    min-width:5vw;
    justify-content:center;
    display:flex;
    align-items:center
}
.calltag_lab_doctor {
    color: white;
    font-size: max(0.625vw,10px);
    display: flex;
    align-items: center;
    margin: 1px 14px;
    font-family: Poppins;
    white-space: nowrap;
    text-overflow: ellipsis;
}

/* ListLocations classes.root */
.location_box_root {
    position: relative;
    margin: 2px;
    min-height: 130px;
    min-width: 160px;
    background-color: #b0b0b0;
    border-radius: 5px;
}
.location_box_root &:hover ~ .location_box_reload{
    display: 'block'
}
.location_box_root  & hover .MuiButtonBase-root {
    display: "block !important"
}

/* ListLocations classes.reload */
.location_box_reload {
    background-color: #1C1D1E;
    color: white;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 5px ;
    padding: 3px;
}

/* ListLocations classes.img */
.location_box_img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

/* ListLocations classes.name */
.location_box_name {
    background-color: #1C1D1E;
    color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 5px;
    padding: 3px;
    display: flex;
    align-items: center;
    width: 100%;
    opacity: 0.8;
}

/* ListLocations classes.secondaryLocationBox */
.location_box_secondary { 
  border: 2.5px solid #f3c200;
}

/* LocationArea classes.locations */
.location_area_locations {
    width:240px;
    height:100vh;
    position:fixed;
    top:0;
    background-color: rgba(200,200,200,0.5);
    overflow-y:auto;
    box-shadow: 0 0 25px #8c8c8c45;
    transition: all 0.5s ease-out;
}
.location_area_locations &:hover {
    background-color: #eeeeee;
}
.location_area_locations &:hover ~ .location_area_toggle{
  display:"block"
}

/* LocationArea classes.toggleBtn */

.location_area_toggle &:focus {
    outline:none;
}

/* LocationArea classes.list */
.location_area_list {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
}

/* LocationArea classes.icon */
.location_area_icon {
    border-radius:5px;
    background-color:white;
    font-size:7rem !important;
    opacity:0.5;
    margin:5px;
    border:1px solid;
}
/* LocationArea classes.holdIcon */
.location_area_hold_icon {
    /* width: 14.9rem; */
    border-radius:5px;
    background-color: rgba(255, 0, 0, 0.7);
    color: rgb(235, 235, 235);
    margin: 5px;
    padding-right: 7px;
    border:1px solid;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 3rem;
}

/* LocationArea classes.holdIconTimer */
.location_area_hold_icon_timer {
    display: flex;
    flex-direction: column;
    line-height: 1;
    margin-left: 5px;
}