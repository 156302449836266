.color_btn {
    display: flex;
    min-width: 14.47vw;
    height: 6.45vw;
    text-align: center;
    margin: 0 1.5vw;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}
.color_btn:hover{
    opacity: 0.9;
    cursor: pointer;
}
.color_btn:active{
    opacity: 0.8;
}
.button_lab {
    font-family: Medium;
    font-size: 1.93vw;
    color: white;
    margin: 0;
}
.oval {
    border-radius: 4vw;
}
.rectangle {
    border-radius: 0.5vw;
}