.remote_reception_area {
    display: flex;
    justify-content: space-between;
    padding: 0;
    /* margin-bottom: 0.7rem; */
    align-items: center;
    
    /* margin-bottom: 30px; */
}
.local_reception_area {
    width: 100%;
    justify-content: center;
    margin: 0px;
}
.reception_img {
    width: 10vw;
    height: 7.35vw;
    background: black;
    border: 4px solid #f5a623;
}
.reception_name {
    /* font-family: Poppins; */
    /* font-size: max(1.14vw, 16px); */
    text-transform: capitalize;
    width: 100%;
    margin: 0;
}
#local_reception_name {
    margin: 0px;
}
.location_name {
    font-family: Poppins;
    font-size: max(0.9vw, 13px);
    text-transform: capitalize;
    /* margin: 0 1em; */
}
.reception_status_area {
    display: flex;
    align-items: center;
    position: relative;
}
.reception_status_lab {
    display: flex;
    width: 4vw;
    height: 1.2vw;
    min-width: 57px;
    min-height: 18px;
    background: #b82121;
    text-align: center;
    border-radius: 30px;
    color: white;
    text-transform: uppercase;
    font-size: max(0.67vw, 10px);
    margin: 0px;
    align-items: center;
    justify-content: center;
}
#drop_down_lab {
    position: absolute;
    top: max(2vw, 20px);
    left: 0px;
}
.drop_icon {
    font-size: 2vw !important;
}
.drop_icon :hover {
    cursor: pointer;
}
.btn-chat{
    padding:5px !important;
    border-radius:5px !important;
}
.btn-chat:hover  {
    background-color:"#404040" !important;
}
.remote-user-view-grid {
    display: flex;
    position: relative;
    margin-right:5px !important;
}
.remote-user-view-number-unread {
    display: flex;
    position: absolute;
    right: -5px;
    top: -5px;
    border-radius:50%;
    color:white;
    background-color:red;
    padding:0px 3px;
}
.patient-main {
    display: flex;
    width: 100%;
    height: 100%;
    background: black;
    flex-direction: column;
    border-radius: 6px;
}
.patient-main-area {
    display: flex;
    position: relative;
    width: 100%;
    height: 93%;
    justify-content:center;
    align-items: center;
}
.patient-front-area {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 6;
}
.patient-back-area{
    position: absolute;
    width: 100%;
    height: 100%;
}
.patient-video-area{
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
}
.patient-black-div{
    position: absolute;
    display: flex;
    background: black;
    z-index: 1;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    visibility: collapse;
}

.patient-main-video-area {
    width: 30%;
    height: 30%;
    position: absolute;
    display: flex;
    right: 10px;
    bottom: 10px;
    z-index: 5;
}
.patient-main-video {
    width: 100%;
    height: 100%;
}
.patient-bottom-area {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    /* min-height: 7%; */
    /* height: auto; */
    /* max-height: 17%; */
    background: #03A9F4;
    align-items: center;
    justify-content: space-between;
    border-radius: 0 0 6px 6px;
    padding: 15px 20px 15px 20px;
}

.patient-greating-lab {
    font-size: 2vh;
    font-weight: bold;
    white-space: nowrap;
}
.patient-name-lab {
    font-size: 2vh;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.patient-user-video{
    width: 100%;
    height: 100%;
}
.patient-phone-icon {
    font-size: 3vh;
    color: red;

}
.patient-phone-icon:hover {
    color: #ff0000cc
}

/* Doctor.js */
.doctor-root {
    display: flex;
    min-height: 100vh;
    width: 100%;
    height: 100%;
    flex-direction: column;
    overflow: hidden
}
.doctor-logo-div{
    top:0;
    left:0;
    z-index:2;
}
.doctor-logo-img{
    /* max-width: 70px; */
    max-height: 60px;
    position: fixed;
    visibility: visible;
    padding: 5px;
}
.reception-self-video {
  display: block;
  width: 100%;
  height: 200px;
  background: black;
  border: 1px solid black;
  top: unset;
}
.reception-self-video-on-top {
  position: fixed;
  left: 40px;
  display: block;
  background: black;
  border: 1px solid black;
}
@media (min-width: 500px) and (max-width: 700px) {
  .reception-self-video-on-top {
    width: 30vw;
  }
}
@media (min-width: 700px) and (max-width: 1000px) {
  .reception-self-video-on-top {
    width: 25vw;
  }
}
@media (min-width: 1000px) and (max-width: 1300px) {
  .reception-self-video-on-top {
    width: 20vw;
  }
}
@media (min-width: 1300px) {
  .reception-self-video-on-top {
    width: 15vw;
  }
}

