/* div::-webkit-scrollbar {
    display: none;
} */
:root {
  --reception-left-sidebar-width: 350px
}
.reception_alert_badge {
  background-color: #D91E18;
  height: 4vh;
  width: 100%;
  text-align: center;
  font-size: large;
  color: #FFFFFF;
  line-height: 4vh;
}
.main_top_area {
    width: 100%;
    height: 9vh;
    display: flex;
    background: #2130b8;
    align-items: center;
}
.main_body_area {
    width: 100%;
    display: flex;
    background: white;
    flex-direction: row;
    justify-content: center;
}
.reception_left_sidebar {
    width: var(--reception-left-sidebar-width);
    height: 100%;
    display: flex;
    position: relative;
    background: white;
    box-shadow: 0 0 25px #8c8c8c45;
    z-index: 1;
    transition: all 0.5s ease-out;
}
@media (max-width: 700px) {
  :root {
    --reception-left-sidebar-width: 250px
  }
  .reception_left_sidebar {
    position: fixed;
    left: 0;
    z-index: 3; 
    width: var(--reception-left-sidebar-width);
  }
}
@media (min-width: 700px) and (max-width: 1200px) {
  :root {
    --reception-left-sidebar-width: 300px
  }
  .reception_left_sidebar {
    position: fixed;
    left: 0;
    z-index: 3; 
    width: var(--reception-left-sidebar-width); 
  }
}
@media (min-width: 1200px) {
  :root {
    --reception-left-sidebar-width: 350px
  }
  .reception_left_sidebar {
    position: relative;
    left: unset;
    z-index: 1; 
    width: var(--reception-left-sidebar-width); 
  }
}
.main_body_location_area {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
}
.lucas_doctor_logo{
    height: 65%;
    padding-left: 57px;
    padding-right: 46px;
    border-right: 1px solid #414fd4;
}
.main_top_title_lab {
    color: white;
    font-size: max(2.03vh, 16px);
    margin-left: 45px;
    font-family: SemiBold;
    margin-bottom: 0px;
}
.main_top_button_area {
    margin-left: auto;
    margin-right: 32px;
}
.btn-no-outline:focus{
  outline: 0 !important;
}